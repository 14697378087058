import React, { useEffect, useState } from "react";
import { getContentTypeFromParamsUtils } from "../../../core/utils/get-contentType-from-params.utils";
import { useUserProgress } from "../../../core/hooks/user-progress/user-progress.hook";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useParams } from "react-router-dom";
import { ContentProgressEnum } from "../../../core/enums/content-progress.enum";
import { RoutesEnum } from "../../../constants/routes/app.route";
import UserProgressOverviewScreen from "../../screens/user-progress/user-progress-overview.screen";
import ContentUserProgressModel from "../../../core/models/user-progress/user-progress/user-progress-types/content-user-progress.model";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import UserModel from "../../../core/models/user/user.model";
import PageHeaderBreadcrumbsComponent from "../../components/page-header/breadcrumbs/page-header-breadcrumbs.component";
import CreateNotificationModal from "../../components/modals/create-notification.modal";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import EmptyStateScreen from "../../screens/empty-state.screen";
import useQuery from "../../../core/hooks/useQuery";
import { selectUserProgressFromUserGroupUsers } from "../../../core/redux/selectors/user-progress/user-progress";
import { useSelector } from "react-redux";
import { selectCurrentlySelectedUserGroup } from "../../../core/redux/selectors/user-group/user-group.selector";

const UserProgressContainer = () => {
  const { t } = useTranslation();
  const params = useParams<{
    playbookUid: string;
    chapterUid?: string;
    collectionUid?: string;
  }>();
  const contentType = getContentTypeFromParamsUtils(params);
  const { data } = useUserProgress(
    params.playbookUid,
    params.chapterUid,
    params.collectionUid,
  );
  const query = useQuery();
  const [contentProgressState, setContentProgressState] = useState(
    ContentProgressEnum.STARTED,
  );
  const [baseRoute, setBaseRoute] = useState<string>(
    RoutesEnum.PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK,
  );
  const [contentUid, setContentUid] = useState<string>();
  const [isModalNotificationVisible, setIsModalNotificationVisible] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>();
  const state: ContentProgressEnum | null = query.get(
    "state",
  ) as ContentProgressEnum | null;

  const currentlySelectedUserGroup: string = useSelector(
    selectCurrentlySelectedUserGroup,
  );

  useEffect(() => {
    if (!state) {
      return;
    }

    setContentProgressState(state);
  }, [state]);

  const userProgressFromUserGroupUsers =
    selectUserProgressFromUserGroupUsers(data);

  useEffect(() => {
    let route = RoutesEnum[`PUBLISHED_CONTENT_USER_PROGRESS_${contentType}`];
    let uid: string | undefined;

    if (contentType === ContentTypesEnum.PLAYBOOK) {
      route = route.replace(":playbookUid", params.playbookUid);
      uid = params.playbookUid;
    }

    if (contentType === ContentTypesEnum.CHAPTER) {
      route = route
        .replace(":playbookUid", params.playbookUid)
        .replace(":chapterUid", params.chapterUid);
      uid = params.chapterUid;
    }

    if (contentType === ContentTypesEnum.COLLECTION) {
      route = route
        .replace(":playbookUid", params.playbookUid)
        .replace(":chapterUid", params.chapterUid)
        .replace(":collectionUid", params.collectionUid);
      uid = params.collectionUid;
    }

    setContentUid(uid);
    setBaseRoute(route);
  }, [params, contentType]);

  function getMenuItems(): MenuItemInterface[] {
    return Object.keys(ContentProgressEnum).map((key) => {
      const hasFilteredUsers =
        userProgressFromUserGroupUsers && currentlySelectedUserGroup !== ""
          ? userProgressFromUserGroupUsers
          : data?.users;

      const count = hasFilteredUsers
        ? hasFilteredUsers.filter((u) => u.state === key).length
        : 0;
      return {
        title: `${Capitalize(
          t(`screens.user-progress.state.${key}`),
        )} (${count})`,
        route: baseRoute + "?state=" + key,
      };
    });
  }

  const filteredUsers = (): ContentUserProgressModel[] => {
    const filtered =
      userProgressFromUserGroupUsers && currentlySelectedUserGroup !== ""
        ? userProgressFromUserGroupUsers
        : data?.users;

    if (!filtered || filtered.length === 0) {
      return [];
    }

    if (!contentProgressState) {
      return filtered;
    }

    return filtered.filter((u) => u.state === contentProgressState);
  };

  return (
    <PageLayoutComponent
      menuItems={getMenuItems()}
      breadCrumbs={<PageHeaderBreadcrumbsComponent />}
    >
      {!data || !userProgressFromUserGroupUsers || !contentUid ? (
        <EmptyStateScreen route={"user-progress"} />
      ) : (
        <UserProgressOverviewScreen
          userProgress={filteredUsers()}
          onSelectNotification={onShowNotificationModal}
          contentProgressState={contentProgressState}
          contentUid={contentUid}
          contentType={contentType}
        />
      )}

      <CreateNotificationModal
        notification={undefined}
        visible={isModalNotificationVisible}
        onCancel={onHideNotificationModal}
        preselectedContent={getPreselectedContent()}
        notifiable={selectedUsers}
      />
    </PageLayoutComponent>
  );

  function onShowNotificationModal(users: UserModel[]) {
    setSelectedUsers(users);
    setIsModalNotificationVisible(true);
  }

  function onHideNotificationModal() {
    setSelectedUsers(undefined);
    setIsModalNotificationVisible(false);
  }

  function getPreselectedContent() {
    switch (contentType) {
      case ContentTypesEnum.PLAYBOOK:
        return [baseRoute.split("/")[5]];
      case ContentTypesEnum.CHAPTER:
        return [baseRoute.split("/")[5], baseRoute.split("/")[7]];
      case ContentTypesEnum.COLLECTION:
        return [
          baseRoute.split("/")[5],
          baseRoute.split("/")[7],
          baseRoute.split("/")[9],
        ];
      case ContentTypesEnum.CARD:
        return;
    }
  }
};

export default UserProgressContainer;
