import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { getRules } from "../../../../core/utils/get-rules.utils";
import CharacterCounter from "./character-counter";
import AppConfig from "../../../../constants/config/app.config";
import TextArea from "antd/lib/input/TextArea";
import styles from "../../card-types/card-types.module.css";

const FormQuestionField = ({
  question,
  setQuestion,
  maxLength = AppConfig.formQuestionMaxLength,
}: {
  question: string;
  setQuestion: (value: string) => void;
  maxLength?: number;
}) => {
  const [t] = useTranslation();

  return (
    <Form.Item
      initialValue={question}
      name={"question"}
      label={Capitalize(t("form.card.question.label"))}
      rules={getRules("question", false)}
    >
      <div>
        <TextArea
          value={question}
          rows={4}
          style={{ width: "80%" }}
          maxLength={maxLength}
          placeholder={t("form.card.question.placeholder")}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <CharacterCounter
          currentLength={question.length}
          maxLength={maxLength}
          className={styles.character_counter}
        />
      </div>
    </Form.Item>
  );
};

export default FormQuestionField;
