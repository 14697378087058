import CreateBaseCardModel, {
  CreateCardInterface,
} from "../create-base-card.model";
import GenericQuizDataModel, {
  GenericQuizDataResponse,
} from "../../content/card-types/generic-quiz/generic-quiz-card-data.model";
import { CardTypesEnum } from "../../../enums/card-types.enum";

export interface CreateGenericQuizCardResponse extends CreateCardInterface {
  data: GenericQuizDataResponse;
}

export default class CreateGenericQuizCardModel extends CreateBaseCardModel {
  cardType: CardTypesEnum.GENERIC_QUIZ;
  data: GenericQuizDataModel;

  constructor(createGenericQuizCard: CreateGenericQuizCardResponse) {
    super(createGenericQuizCard);
    this.cardType = CardTypesEnum.GENERIC_QUIZ;
    this.data = new GenericQuizDataModel(createGenericQuizCard.data);
  }
}
