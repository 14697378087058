import React from "react";
import SleepingUsersAnalytics from "./sleeping-users.analytics";

const AnalyticsContainer = () => {
  return (
    <div>
      <SleepingUsersAnalytics />
    </div>
  );
};

export default AnalyticsContainer;
