import React from "react";
import { CardTypesEnum } from "../../../core/enums/card-types.enum";
import KnowledgeCardPreviewComponent from "../card-types/knowledge-card/knowledge-card-preview.component";
import OptionCardPreviewComponent from "../card-types/option-card/option-card-preview.component";
import QuestionCardPreviewComponent from "../card-types/question-card/question-card-preview.component";
import CardTypes from "../../../core/types/card.types";
import PictureQuizCardPreviewComponent from "../card-types/picture-quiz/picture-quiz-card-preview.component";

interface Props {
  card: CardTypes;
}

const CardDetailComponent = ({ card }: Props) => {
  switch (card.cardType) {
    case CardTypesEnum.KNOWLEDGE_CARD:
      return <KnowledgeCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.QUESTION:
      return <QuestionCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.PICTURE_QUIZ:
      return <PictureQuizCardPreviewComponent cardContent={card} />;
    case CardTypesEnum.QUIZ:
    case CardTypesEnum.MULTIPLE_CHOICE:
    case CardTypesEnum.SLIDER:
    case CardTypesEnum.GENERIC_QUIZ:
      return (
        <OptionCardPreviewComponent
          cardContent={card as any}
          showBaseFields={false}
        />
      );
  }
};

export default CardDetailComponent;
