import React, { useEffect } from "react";
import { Checkbox, Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useForm } from "antd/lib/form/Form";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import CharacterCounter from "../../form-builder/components/character-counter";
import MultipleChoiceOptionModel from "../../../../core/models/content/card-types/multiple-choice/multiple-choice-option.model";
import QuizOptionModel from "../../../../core/models/content/card-types/quiz/quiz-option.model";
import SliderOptionModel from "../../../../core/models/content/card-types/slider/slider-option.model";
import AppConfig from "../../../../constants/config/app.config";
import styles from "../card-types.module.css";
import GenericQuizOptionModel from "../../../../core/models/content/card-types/generic-quiz/generic-quiz-option.model";

export interface CreateOptionInterface {
  content: string;
  explanation: string | undefined;
  isCorrect: boolean | undefined;
}

const CreateOptionForm = ({
  index,
  option,
  onChangeOption,
  showExplanation = false,
  contentMaxLength = AppConfig.formAnswerContentMaxLength,
  explanationMaxLength = AppConfig.formAnswerExplanationMaxLength,
}: {
  index: number;
  option:
    | MultipleChoiceOptionModel
    | QuizOptionModel
    | SliderOptionModel
    | GenericQuizOptionModel;
  onChangeOption: (option: CreateOptionInterface) => void;
  showExplanation?: boolean;
  contentMaxLength?: number;
  explanationMaxLength?: number;
}) => {
  const [t] = useTranslation();
  const [form] = useForm<CreateOptionInterface>();

  const content: string =
    option instanceof SliderOptionModel
      ? TranslateDefaults(option.label ? option.label : "")
      : TranslateDefaults(option.content ? option.content : "");
  const explanation: string | undefined =
    showExplanation && "explanation" in option
      ? TranslateDefaults(option.explanation ? option.explanation : "")
      : undefined;
  const isCorrect: boolean | undefined =
    option instanceof SliderOptionModel ? undefined : option.isCorrect;

  useEffect(() => {
    form.setFieldsValue({ content, explanation, isCorrect });
  });

  useEffect(() => {
    validateAndSubmit();
  }, [option]);

  async function validateAndSubmit(): Promise<boolean> {
    return await form
      .validateFields()
      .then(() => true)
      .catch(() => false);
  }

  return (
    <Form form={form} style={{ flex: 1 }} component={false}>
      <div className={styles.options_content_wrapper}>
        {renderPrefix()}
        <Form.Item
          initialValue={content}
          name={"content"}
          className={styles.options_content_item}
          rules={getRules("content")}
        >
          <Input
            value={content}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={content.length}
                maxLength={contentMaxLength}
              />
            }
            maxLength={contentMaxLength}
            placeholder={t("form.card.answers.placeholder")}
            onChange={() =>
              onChangeOption({
                content: form.getFieldsValue().content,
                explanation,
                isCorrect,
              })
            }
          />
        </Form.Item>
      </div>

      {explanation !== undefined && (
        <Form.Item
          initialValue={explanation}
          name={"explanation"}
          className={styles.options_explanation_item}
          rules={getRules("explanation")}
        >
          <Input
            value={explanation}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={explanation?.length ?? 0}
                maxLength={explanationMaxLength}
              />
            }
            maxLength={explanationMaxLength}
            placeholder={t("form.card.answers.feedback")}
            onChange={() =>
              onChangeOption({
                content,
                explanation: form.getFieldsValue().explanation,
                isCorrect,
              })
            }
          />
        </Form.Item>
      )}
    </Form>
  );

  function renderPrefix() {
    if (option instanceof SliderOptionModel) {
      return <Typography.Text>{`${index + 1}.`}</Typography.Text>;
    } else {
      return (
        <Form.Item
          initialValue={isCorrect}
          name={"isCorrect"}
          style={{ marginLeft: ".5rem" }}
        >
          <Checkbox
            value={isCorrect}
            checked={isCorrect}
            onChange={(e) => {
              form.setFieldsValue({ isCorrect: !e.target.value });
              onChangeOption({
                content,
                explanation,
                isCorrect: form.getFieldsValue().isCorrect,
              });
            }}
          />
        </Form.Item>
      );
    }
  }

  function getRules(field: string) {
    const maxLength =
      field === "explanation" ? explanationMaxLength : contentMaxLength;

    let label: string;
    if (field === "explanation") {
      label = `form.card.explanation.label`;
    } else {
      label = `form.card.answers.label`;
    }

    return [
      {
        max: maxLength,
        message: Capitalize(
          t("errors.max-length", {
            field: t(label),
            amount: maxLength,
          }),
        ),
      },
      {
        required: true,
        message: Capitalize(
          t("errors.required", {
            item: t(label),
          }),
        ),
      },
    ];
  }
};

export default CreateOptionForm;
