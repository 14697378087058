import React, { useEffect, useRef, useState } from "react";
import NotificationTypes from "../../../core/types/notification.types";
import { NotificationCreateInterface } from "../../../core/interfaces/notification-create.interface";
import { useDispatch, useSelector } from "react-redux";
import CreateNotificationForm, {
  CreateNotificationFormRef,
} from "../forms/create-notification.form";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { Button, Modal, Result } from "antd";
import { NotificationTypesEnum } from "../../../core/enums/notification-types.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import NotificationAction from "../../../core/redux/stores/notification/notification.action";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import UserModel from "../../../core/models/user/user.model";

interface OwnProps {
  notification?: NotificationTypes;
  visible: boolean;
  onCancel: () => void;
  notifiable?: UserModel[] | UserGroupModel[];
  preselectedContent?: string[];
}

type Props = OwnProps;

const CreateNotificationModal = (props: Props) => {
  const [t] = useTranslation();
  const { notification, visible, onCancel, notifiable, preselectedContent } =
    props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const createNotificationFormRef = useRef<CreateNotificationFormRef>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const requesting = useSelector((state) =>
    selectRequesting(state, [NotificationAction.REQUEST_CREATE_NOTIFICATION]),
  );

  useEffect(() => {
    if (!requesting && isSubmitting) {
      setIsSubmitting(false);

      if (isSubmitting) {
        handleOnFinish();
      }
    }
  }, [requesting]);

  const handleOnFinish = () => {
    if (createNotificationFormRef.current) {
      createNotificationFormRef.current.form.resetFields();
    }

    if (!notifiable) {
      return onCancel();
    }

    setConfirmLoading(true);
  };

  const getNotifiableUtility = ():
    | UserModel[]
    | UserGroupModel[]
    | undefined => {
    if (!notifiable) {
      return;
    }

    return notifiable.map((not: UserModel | UserGroupModel) => {
      let newNotifiable;
      if (not instanceof UserModel) {
        newNotifiable = not as UserModel;
      } else {
        newNotifiable = not as UserGroupModel;
      }
      return newNotifiable;
    });
  };

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      width={"80rem"}
      destroyOnClose
      afterClose={() => setConfirmLoading(false)}
      okText={Capitalize(t("common.send"))}
      okButtonProps={{ disabled: confirmLoading }}
      onOk={handleOnOk}
      cancelText={Capitalize(t("common.cancel"))}
      cancelButtonProps={{ disabled: confirmLoading }}
      onCancel={handleOnCancel}
    >
      {confirmLoading ? (
        <Result
          status={"success"}
          title={Capitalize(
            t("errors.warnings.succes_create-x", {
              container: t("containers.notifications.key"),
            }),
          )}
          subTitle={renderSubtitle()}
        />
      ) : (
        <CreateNotificationForm
          ref={createNotificationFormRef}
          notification={notification}
          preselectedContent={preselectedContent}
          notifiable={getNotifiableUtility()}
        />
      )}
    </Modal>
  );

  function renderSubtitle() {
    return (
      <Button
        key={2}
        type={"primary"}
        onClick={() => {
          history.push(RoutesEnum.NOTIFICATIONS);
          onCancel();
          setConfirmLoading(false);
        }}
      >
        {Capitalize(
          t("common.redirect_to", {
            container: t("containers.notifications.key_plural"),
          }),
        )}
      </Button>
    );
  }

  function handleOnOk() {
    if (createNotificationFormRef.current) {
      createNotificationFormRef.current.form.validateFields().then((res) => {
        let data: NotificationCreateInterface = {
          notificationType: NotificationTypesEnum.ANNOUNCEMENT,
          notifiable: {
            notifiableType: res.notifiableType,
            recipients: res.recipients,
          },
          data: {
            title: res.title,
            subtitle: res.subtitle,
            message: res.message,
          },
          sendAt: res.sendAt,
        };

        if (res.content) {
          data = {
            ...data,
            notificationType: NotificationTypesEnum.CONTENT,
            data: {
              ...data.data,
              playbookUid: res.content[0] as string,
              chapterUid: res.content[1] as string,
              collectionUid: res.content[2] as string,
              cardUid: res.content[3] as string,
            },
          };
        }

        if (res.learningPathUid) {
          data = {
            ...data,
            notificationType: NotificationTypesEnum.CONTENT,
            data: {
              ...data.data,
              learningPathUid: res.learningPathUid,
              playbookUid: "...", // cant be empty but needs to be a string
            },
          };
        }

        dispatch(NotificationAction.createNotification(data));
        setIsSubmitting(true);
      });
    }
  }

  function handleOnCancel() {
    onCancel();
    if (createNotificationFormRef.current) {
      createNotificationFormRef.current.form.resetFields();
    }
  }

  function renderModalTitle() {
    if (notification && notification.notificationType) {
      return Capitalize(
        t(`screens.notifications.type.${notification.notificationType}`),
      );
    }
    return Capitalize(
      t("common.new-plural") + " " + t(`containers.notifications.key`),
    );
  }
};

export default CreateNotificationModal;
