import React from "react";
import { Alert, Card, Form, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContentListComponent from "../../components/content-list/content-list.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import QrCodeModel, {
  QrCodeData,
} from "../../../core/models/qr-code/qr-code.model";
import QrCodeComponent from "../../components/qr-code/qr-code.component";
import styles from "../../components/content/content.module.css";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { CLIENT_NAME } from "../../../constants/config/env.config";

interface OwnProps {
  qrCode: QrCodeModel;
}

const QrCodeDetailOverviewScreen = ({ qrCode }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <Form labelCol={{ span: 4 }} layout={"horizontal"} labelAlign={"left"}>
      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form.Item label={renderLabel("form.items.content.linked")}>
          {renderLinkedContent()}
        </Form.Item>
        <Form.Item
          label={renderLabel("form.card.description.label")}
          style={{ marginBottom: 0 }}
        >
          <Typography.Text>{qrCode.description}</Typography.Text>
        </Form.Item>
      </Card>

      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form.Item
          label={renderLabel("common.accessibility")}
          style={{ marginBottom: 0 }}
        >
          {qrCode.isPublic
            ? Capitalize(
                t("common.accessible-to_x", { item: t("common.everyone") }),
              )
            : Capitalize(t("common.only")) +
              " " +
              t("common.accessible-to_x", {
                item: `${CLIENT_NAME} ${t("containers.users.key_plural")}`,
              })}
        </Form.Item>
        <Form.Item
          label={renderLabel("common.link")}
          style={{ marginBottom: 0 }}
        >
          {qrCode.openInApp
            ? t("translations:screens.qr.link.app")
            : t("translations:screens.qr.link.website")}
        </Form.Item>
      </Card>

      <Card className={styles.content} style={{ marginBottom: "5rem" }}>
        <Form.Item
          label={renderLabel("containers.qr.key")}
          style={{ marginBottom: 0 }}
        >
          {qrCode.contentState === ContentStatesEnum.DRAFT && (
            <Alert
              showIcon
              banner
              message={t("translations:screens.qr.draft-warning")}
              type={"warning"}
            />
          )}
          <QrCodeComponent qrCode={qrCode} />
        </Form.Item>
      </Card>
    </Form>
  );

  function renderLabel(text: string) {
    return <Typography.Text strong>{Capitalize(t(text))}</Typography.Text>;
  }

  function renderLinkedContent() {
    let qrCodeData: QrCodeData;

    switch (qrCode.data.contentType) {
      case ContentTypesEnum.PLAYBOOK:
        qrCodeData = {
          playbookUid: qrCode.data.playbookUid,
        };
        break;
      case ContentTypesEnum.CHAPTER:
        qrCodeData = {
          playbookUid: qrCode.data.playbookUid,
          chapterUid: qrCode.data.chapterUid,
        };
        break;
      case ContentTypesEnum.COLLECTION:
        qrCodeData = {
          playbookUid: qrCode.data.playbookUid,
          chapterUid: qrCode.data.chapterUid,
          collectionUid: qrCode.data.collectionUid,
        };
        break;
      case ContentTypesEnum.CARD:
        qrCodeData = {
          playbookUid: qrCode.data.playbookUid,
          chapterUid: qrCode.data.chapterUid,
          collectionUid: qrCode.data.collectionUid,
          cardUid: qrCode.data.cardUid,
        };
        break;
    }

    return <ContentListComponent data={qrCodeData} />;
  }
};

export default QrCodeDetailOverviewScreen;
