import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import NotificationTypes from "../../../core/types/notification.types";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { FormInstance, useForm } from "antd/lib/form/Form";
import type { RadioChangeEvent } from "antd";
import { Divider, Form, Input, Radio, Select, Switch, Typography } from "antd";
import { NotifiableTypeEnum } from "../../../core/enums/notification-types.enum";
import {
  selectCurrentUserRole,
  selectUsers,
} from "../../../core/redux/selectors/user/user.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import UserSelectComponent from "./select/user-select.component";
import DateTimePickerComponent from "./select/date-time-picker.component";
import UserGroupSelectComponent from "./select/user-groups-select.component";
import CharacterCounter from "../form-builder/components/character-counter";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import EmojiPickerField from "../form-builder/components/emoji-picker.field";
import UserModel from "../../../core/models/user/user.model";
import AppConfig from "../../../constants/config/app.config";
import TextArea from "antd/lib/input/TextArea";
import cardTypeStyles from "../card-types/card-types.module.css";
import Moment from "moment";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { useGetLearningPaths } from "../../../core/api/primio/primioComponents";
import { LearningPath } from "../../../core/api/primio/primioSchemas";
import ContentCascaderComponent from "./select/content-cascader.component";

export interface CreateNotificationFormFields {
  content: (string | number)[] | undefined;
  title: string;
  subtitle?: string;
  message: string;
  notifiableType: NotifiableTypeEnum;
  recipients: string[];
  sendAt: Date;
  learningPathUid?: string;
}

enum NotificationContentType {
  MICRO_LEARNING = "MICRO_LEARNING",
  LEARNING_PATH = "LEARNING_PATH",
}

export interface CreateNotificationFormRef {
  form: FormInstance<CreateNotificationFormFields>;
}

interface OwnProps {
  notification: NotificationTypes | undefined;
  notifiable: UserModel[] | UserGroupModel[] | undefined;
  preselectedContent?: string[];
}

type Props = OwnProps;

const CreateNotificationForm = forwardRef(
  (props: Props, ref: Ref<CreateNotificationFormRef>) => {
    const [t] = useTranslation();
    const { notification, notifiable, preselectedContent } = props;
    const users: UserModel[] = useSelector((state: StoreInterface) =>
      selectUsers(state),
    );
    const [form] = useForm<CreateNotificationFormFields>();
    const [content, setContent] = useState<string[]>(preselectedContent ?? []);
    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [notifiableType, setNotifiableType] = useState<NotifiableTypeEnum>(
      getNotifiableTypeUtility(),
    );
    const [notificationContentType, setNotificationContentType] = useState(
      NotificationContentType.MICRO_LEARNING,
    );
    const [recipients, setRecipients] = useState<string[]>(
      setRecipientsUtility(),
    );
    const [switchAllUsers, setSwitchAllUsers] = useState<boolean>(
      notifiableType === NotifiableTypeEnum.USERS && users.length > 0
        ? recipients.length >= users.length
        : false,
    );
    const [dateTime, setDateTime] = useState<Moment.Moment | null>(
      preselectedContent ? Moment(new Date()) : null,
    );
    const [switchDateTime, setSwitchDateTime] = useState<boolean>(false);
    const userRole: UserRoleEnum | undefined = useSelector(
      selectCurrentUserRole,
    );

    const titleMaxLength = AppConfig.notificationTitleMaxLength;
    const subtitleMaxLength = AppConfig.notificationSubtitleMaxLength;
    const messageMaxLength = AppConfig.notificationMessageMaxLength;

    const { data: learningPaths = [] as LearningPath[] } = useGetLearningPaths(
      {},
    );

    const handleOnChangeNotifiableType = (e: RadioChangeEvent) => {
      handleOnChangeRecipients([]);
      setNotifiableType(e.target.value);
      form.setFields([{ name: "notifiableType", value: e.target.value }]);
    };

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      form.setFields([
        { name: "notifiableType", value: NotifiableTypeEnum.USER_GROUPS },
      ]);
    }, []);

    useEffect(() => {
      form.setFields([{ name: "title", value: title }]);
    }, [title]);

    useEffect(() => {
      form.setFields([{ name: "subtitle", value: subtitle }]);
    }, [subtitle]);

    useEffect(() => {
      form.setFields([{ name: "message", value: message }]);
    }, [message]);

    useEffect(() => {
      form.setFields([
        { name: "notificationContentType", value: notificationContentType },
      ]);
    }, [notificationContentType]);

    function setRecipientsUtility(): string[] {
      if (notification) {
        return notification.notifiable.recipients
          ? notification.notifiable.recipients
          : [];
      }

      if (!notifiable) {
        return [];
      }

      return notifiable.map((not) => {
        if (not instanceof UserModel) {
          return not.username as string;
        } else {
          return not.title as string;
        }
      });
    }

    function getNotifiableTypeUtility(): NotifiableTypeEnum {
      let notifiableType: NotifiableTypeEnum = NotifiableTypeEnum.USER_GROUPS;

      if (!notifiable) {
        return notifiableType;
      }

      notifiable.map((recipient) => {
        if (recipient instanceof UserModel) {
          notifiableType = NotifiableTypeEnum.USERS;
          form.setFields([
            { name: "notifiableType", value: NotifiableTypeEnum.USERS },
          ]);
        } else {
          notifiableType = NotifiableTypeEnum.USER_GROUPS;
          form.setFields([
            { name: "notifiableType", value: NotifiableTypeEnum.USER_GROUPS },
          ]);
        }
      });

      return notifiableType;
    }

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={title}
          name={"title"}
          label={Capitalize(t("form.card.title.label"))}
          rules={getRules("title")}
        >
          <div>
            <Input
              value={title}
              type={"text"}
              suffix={
                <Typography.Text>
                  {title ? title.length : 0}/{titleMaxLength}
                </Typography.Text>
              }
              maxLength={titleMaxLength}
              style={{ width: "74%" }}
              autoFocus
              placeholder={t("form.placeholders.type-of_x", {
                item: t("form.card.title.label"),
                field: t("containers.notifications.key"),
              })}
              onChange={(e) => setTitle(e.target.value)}
            />

            <EmojiPickerField text={title} setText={setTitle} />
          </div>
        </Form.Item>

        <Form.Item
          initialValue={subtitle}
          name={"subtitle"}
          label={Capitalize(t("form.items.subtitle.label"))}
        >
          <div>
            <Input
              value={subtitle}
              type={"text"}
              suffix={
                <Typography.Text>
                  {subtitle ? subtitle.length : 0}/{subtitleMaxLength}
                </Typography.Text>
              }
              maxLength={subtitleMaxLength}
              style={{ width: "74%" }}
              placeholder={t("form.placeholders.type-of_x", {
                item: t("form.items.subtitle.label"),
                field: t("containers.notifications.key"),
              })}
              onChange={(e) => setSubtitle(e.target.value)}
            />

            <EmojiPickerField text={subtitle} setText={setSubtitle} />
          </div>
        </Form.Item>

        <Form.Item
          initialValue={message}
          name={"message"}
          label={Capitalize(t("form.items.text.label"))}
          rules={getRules("message")}
        >
          <div>
            <TextArea
              value={message}
              rows={4}
              style={{ width: "80%" }}
              maxLength={messageMaxLength}
              placeholder={t("form.items.text.placeholder")}
              onChange={(e) => setMessage(e.target.value)}
            />

            <div
              className={cardTypeStyles.character_counter}
              style={{ right: "20.5%" }}
            >
              <CharacterCounter
                currentLength={message ? message.length : 0}
                maxLength={messageMaxLength}
              />
              <EmojiPickerField text={message} setText={setMessage} />
            </div>
          </div>
        </Form.Item>

        <Form.Item
          initialValue={dateTime}
          name={"sendAt"}
          style={{ flex: 1, display: preselectedContent ? "none" : "" }}
          label={Capitalize(t("form.items.send.send-at"))}
          rules={getRules("date")}
        >
          <DateTimePickerComponent
            dateTime={dateTime}
            switchDateTime={switchDateTime}
            onSelect={handleOnSelectDate}
            onChange={handleOnChangeDate}
          />
        </Form.Item>

        <Divider />

        <Form.Item
          name={"notificationContentType"}
          initialValue={notificationContentType}
          label={Capitalize(t("translations:common.attachment"))}
        >
          <Radio.Group
            onChange={(e) => setNotificationContentType(e.target.value)}
            value={notificationContentType}
          >
            <Radio value={NotificationContentType.MICRO_LEARNING}>
              {Capitalize(t("translations:containers.modules.key_plural"))}
            </Radio>
            <Radio value={NotificationContentType.LEARNING_PATH}>
              {Capitalize(
                t("translations:containers.learning-path.key_plural"),
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>

        {notificationContentType === NotificationContentType.MICRO_LEARNING && (
          <Form.Item
            initialValue={content}
            name={"content"}
            label={Capitalize(t("form.items.content.label"))}
          >
            <ContentCascaderComponent
              content={content}
              setContent={handleSetContent}
            />
          </Form.Item>
        )}

        {notificationContentType === NotificationContentType.LEARNING_PATH && (
          <Form.Item
            name={"learningPathUid"}
            label={Capitalize(t("translations:containers.learning-path.key"))}
          >
            <Select
              value={""}
              placeholder={Capitalize(
                t("translations:form.placeholders.select_x", {
                  x: t("translations:containers.learning-path.key"),
                }),
              )}
              options={learningPaths.map((path) => {
                let label = path.title;

                if (path.state !== "POSTED") label += ` (Draft)`;

                return { label, value: path.learningPathUid };
              })}
            />
          </Form.Item>
        )}

        <Divider />

        <Form.Item
          name={"notifiableType"}
          label={Capitalize(t("common.recipients"))}
        >
          <Radio.Group
            onChange={handleOnChangeNotifiableType}
            value={notifiableType}
          >
            <Radio value={NotifiableTypeEnum.USER_GROUPS}>
              {Capitalize(t("containers.user-groups.title"))}
            </Radio>
            <Radio value={NotifiableTypeEnum.USERS}>
              {Capitalize(t("containers.users.title"))}
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          initialValue={recipients}
          name={"recipients"}
          label={renderRecipientsLabel()}
          rules={getRecipientsRules()}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            {renderRecipients()}
            {notifiableType === NotifiableTypeEnum.USERS && (
              <Form.Item
                style={{
                  marginLeft: "3rem",
                  marginBottom: "0",
                }}
                label={Capitalize(t("containers.users.sidebar"))}
              >
                <Switch
                  checked={switchAllUsers}
                  onChange={handleOnSwitchAllUsers}
                />
              </Form.Item>
            )}
          </div>
        </Form.Item>
      </Form>
    );

    // Content
    function handleSetContent(value: string[]) {
      setContent(value);
      form.setFields([{ name: "content", value }]);
    }

    // Recipients
    function renderRecipientsLabel() {
      return notifiableType === NotifiableTypeEnum.USER_GROUPS
        ? Capitalize(t("containers.user-groups.key_plural"))
        : Capitalize(t("containers.users.key_plural"));
    }

    function renderRecipients() {
      return notifiableType === NotifiableTypeEnum.USER_GROUPS ? (
        <UserGroupSelectComponent
          groupNames={recipients}
          onChange={handleOnChangeRecipients}
        />
      ) : (
        <UserSelectComponent
          userNames={recipients}
          users={users.length > 0 ? users : []}
          onChange={handleOnChangeRecipients}
        />
      );
    }

    function handleOnChangeRecipients(value: string[]) {
      if (notifiableType === NotifiableTypeEnum.USERS) {
        setSwitchAllUsers(value.length === users.length);
      }
      form.setFields([{ name: "recipients", value }]);
      setRecipients(value);
    }

    function handleOnSwitchAllUsers(value: boolean) {
      setSwitchAllUsers(value);
      if (notifiableType === NotifiableTypeEnum.USERS) {
        if (users)
          handleOnChangeRecipients(
            value ? users.map((option) => option.username) : [],
          );
      }
    }

    // Date
    function handleOnSelectDate(e: Moment.Moment) {
      setSwitchDateTime(
        Moment(e).format("YYYY-MM-DD HH:mm:ss") ===
          Moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      );
      form.setFields([{ name: "sendAt", value: e }]);
      setDateTime(e);
    }

    function handleOnChangeDate(value: boolean) {
      setSwitchDateTime(value);
      form.setFields([
        {
          name: "sendAt",
          value: value ? Moment(new Date()) : null,
        },
      ]);
      setDateTime(value ? Moment(new Date()) : null);
    }

    // Rules
    function getRules(formItem: string) {
      let maxLength;
      let label: string;

      if (formItem === "title") {
        label = `form.card.title.label`;
        maxLength = titleMaxLength;
      } else if (formItem === "message") {
        label = `form.items.text.label`;
        maxLength = messageMaxLength;
      } else {
        label = `common.date`;
      }

      if (formItem === "date") {
        return [
          {
            required: true,
            message: t("errors.required", { item: t(label) }),
          },
        ];
      }

      return [
        {
          max: maxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t(label),
              amount: maxLength,
            }),
          ),
        },
        {
          required: true,
          message: t("errors.required", { item: t(label) }),
        },
      ];
    }

    function getRecipientsRules() {
      if (!userRole || notifiableType === NotifiableTypeEnum.USERS) {
        return [];
      }

      switch (userRole) {
        case UserRoleEnum.SYS_ADMIN:
        case UserRoleEnum.PRIMIO_SUPPORT:
        case UserRoleEnum.ADMIN:
          return [];
        case UserRoleEnum.GROUP_ADMIN:
          return [
            {
              required: true,
              message: t("translations:errors.required-usergroups"),
            },
          ];
      }
    }
  },
);

CreateNotificationForm.displayName = "CreateNotificationForm";

export default CreateNotificationForm;
