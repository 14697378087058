import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { selectContentTrees } from "../../../../core/redux/selectors/content/content.selector";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { Cascader } from "antd";
import PlaybookDetailModel from "../../../../core/models/content/playbook-detail.model";
import TextDecodeUtils from "../../../../core/utils/text-decode.utils";

interface Option {
  value: string | number;
  label?: React.ReactNode;
  disabled?: boolean;
  children?: Option[];
  // Determines if this is a leaf node(effective when `loadData` is specified).
  // `false` will force trade TreeNode as a parent node.
  // Show expand icon even if the current node has no children.
  isLeaf?: boolean;
}

interface OwnProps {
  content: string[];
  setContent: (value: string[]) => void;
}

type Props = OwnProps;

const ContentCascaderComponent = ({ content, setContent }: Props) => {
  const [t] = useTranslation();
  const playbookDetails: PlaybookDetailModel[] = useSelector(
    (state: StoreInterface) => selectContentTrees(state),
  );

  const options: Option[] = playbookDetails.map((play) => ({
    value: play.playbookUid,
    label: TextDecodeUtils(play.title),
    contentState: play.contentState,
    contentType: play.contentType,
    disabled: play.contentState === ContentStatesEnum.DRAFT,
    children: play.children?.map((chap) => ({
      value: chap.chapterUid,
      label: TextDecodeUtils(chap.title),
      contentState: chap.contentState,
      contentType: chap.contentType,
      disabled: chap.contentState === ContentStatesEnum.DRAFT,
      children: chap.children?.map((col) => ({
        value: col.collectionUid,
        label: TextDecodeUtils(col.title),
        contentState: col.contentState,
        contentType: col.contentType,
        disabled: col.contentState === ContentStatesEnum.DRAFT,
        children: col.children?.map((card) => ({
          value: card.cardUid,
          label: TextDecodeUtils(card.title),
          contentState: card.contentState,
          contentType: card.contentType,
          disabled: card.contentState === ContentStatesEnum.DRAFT,
        })),
      })),
    })),
  }));

  return (
    <Cascader
      placeholder={Capitalize(
        t("form.placeholders.display_x", {
          field: t("form.items.content.label"),
        }),
      )}
      defaultValue={content}
      // @ts-ignore
      onChange={setContent}
      autoFocus={true}
      popupClassName={"Cascader"}
      options={options as any[]}
      changeOnSelect
    />
  );
};

export default ContentCascaderComponent;
