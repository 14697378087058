import React, { useRef } from "react";
import CreateQrCodeForm, {
  CreateQRCodeFormFields,
  CreateQRCodeFormRef,
} from "../forms/create-qr-code.form";
import { Button, Modal } from "antd";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import QrCodeModel, {
  QrCodeResponse,
} from "../../../core/models/qr-code/qr-code.model";
import {
  useCreateQrCode,
  useEditQrCode,
  usePublishQrCode,
} from "../../../core/features/qr-code/qr-code.hooks";

interface OwnProps {
  qrCode?: QrCodeModel;
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps;

const CreateQrCodeModal = (props: Props) => {
  const { t } = useTranslation();
  const { qrCode, visible, onCancel } = props;
  const createQRCodeFormRef = useRef<CreateQRCodeFormRef>(null);
  const { mutateAsync: edit } = useEditQrCode();
  const { mutateAsync: create } = useCreateQrCode();
  const { mutateAsync: publish } = usePublishQrCode();

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      width={"80rem"}
      destroyOnClose
      onCancel={handleOnCancel}
      footer={[
        <Button key={0} type={"text"} onClick={handleOnCancel}>
          {Capitalize(t("common.cancel"))}
        </Button>,
        <Button key={1} type={"primary"} onClick={handleOnOk}>
          {renderOnOkLabel()}
        </Button>,
      ]}
    >
      <CreateQrCodeForm ref={createQRCodeFormRef} qrCode={qrCode} />
    </Modal>
  );

  function handleOnOk() {
    if (createQRCodeFormRef.current) {
      createQRCodeFormRef.current.form
        .validateFields()
        .then((res: CreateQRCodeFormFields) => {
          const [playbookUid, chapterUid, collectionUid, cardUid] = res.content;

          const data: Partial<QrCodeResponse> = {
            description: res.description,
            contentRestrictive: true,
            contentState: ContentStatesEnum.DRAFT,
            isPublic: res.isPublic,
            openInApp: res.openInApp,
            data: {
              playbookUid,
              chapterUid,
              collectionUid,
              cardUid,
            },
          };

          if (res.publishNow) {
            create(data).then((r) => {
              if (r) publish(r).finally(onCancel);
            });
            return;
          }

          if (qrCode && qrCode.qrCodeUid) {
            edit({ qrCode: data, qrCodeUid: qrCode.qrCodeUid }).finally(
              onCancel,
            );
          } else {
            create(data).finally(onCancel);
          }
        });
    }
  }

  function handleOnCancel() {
    onCancel();
    if (createQRCodeFormRef.current) {
      createQRCodeFormRef.current.form.resetFields();
    }
  }

  function renderModalTitle() {
    if (qrCode && qrCode.qrCodeUid) {
      return Capitalize(t("common.edit_x", { name: t("containers.qr.key") }));
    }
    return (
      Capitalize(t("common.new-plural")) +
      " " +
      Capitalize(t("containers.qr.key"))
    );
  }

  function renderOnOkLabel() {
    if (qrCode && qrCode.qrCodeUid) {
      return Capitalize(t("common.edit"));
    }
    return Capitalize(t("common.create"));
  }
};

export default CreateQrCodeModal;
