import GenericQuizOptionModel, {
  GenericQuizOptionResponse,
} from "./generic-quiz-option.model";
import { MapResponseToModel } from "../../../../utils/map-response-to-model.utils";

export interface GenericQuizDataResponse {
  options: GenericQuizOptionResponse[];
  question: string;
  showFeedback: boolean;
  isPractise: boolean;
}

export default class GenericQuizDataModel {
  options: GenericQuizOptionModel[];
  question: string;
  showFeedback: boolean;
  isPractise: boolean;

  constructor(data: GenericQuizDataResponse) {
    this.options = MapResponseToModel(data.options, GenericQuizOptionModel);
    this.question = data.question;
    this.showFeedback = data.showFeedback;
    this.isPractise = data.isPractise;
  }
}
