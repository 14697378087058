export interface GenericQuizOptionResponse {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation?: string;
}

export default class GenericQuizOptionModel {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation?: string;

  constructor(options: GenericQuizOptionResponse) {
    this.sort = options.sort;
    this.content = options.content;
    this.isCorrect = options.isCorrect;
    this.explanation = options.explanation;
  }
}
