import React, { useEffect, useRef, useState } from "react";
import { ContentActionProps } from "../content-action/content-action.component";
import { connect } from "react-redux";
import {
  CardTypesColorEnum,
  CardTypesEnum,
} from "../../../core/enums/card-types.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { useContentSidebar } from "../../../core/providers/content-sidebar.provider";
import TextDecodeUtils from "../../../core/utils/text-decode.utils";
import CardBoilerplate from "./card.boilerplate";
import CardDetailComponent from "./card-detail.component";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import CardTypeIconComponent from "../icons/card-type-icon.component";
import CreateSliderCardForm from "../card-types/slider-card/create-slider-card.form";
import CreateKnowledgeCardForm from "../card-types/knowledge-card/create-knowledge-card.form";
import CreateQuizCardForm from "../card-types/quiz-card/create-quiz-card.form";
import CreateMultipleCardForm from "../card-types/multiple-choice-card/create-multiple-card.form";
import CreateQuestionCardForm from "../card-types/question-card/create-question-card.form";
import isInViewport from "../../../core/utils/is-in-viewport.utils";
import ContentContextActionsComponent, {
  ContentContextRef,
} from "../content-context-actions/content-context-actions.component";
import Emitter from "../../../core/utils/emitter.utils";
import ContentType from "../../../core/types/content.type";
import CardTypes from "../../../core/types/card.types";
import styles from "../content/content.module.css";
import CardTitleIconComponent from "./card-title-icon.component";
import { selectCardsCountWithSameUidViaUid } from "../../../core/redux/selectors/content/content.selector";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import CreatePictureQuizCardForm from "../card-types/picture-quiz/create-picture-quiz-card.form";
import CreateGenericQuizCardForm from "../card-types/generic-quiz-card/create-generic-quiz-card.form";

interface StateProps {
  cardsCountWithSameUid: CardTypes[];
}

interface OwnProps {
  card: CardTypes;
}

type Props = OwnProps & StateProps;

const CardComponent = (props: Props) => {
  const [t] = useTranslation();
  const { cardsCountWithSameUid, card } = props;
  const { onClick, isSelected } = useContentSidebar();
  const [editing, setEditing] = useState<boolean>(card.shouldEdit);
  const contentContextActionsRef = useRef<ContentContextRef>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentArray: ContentType[] = [];
  //
  const className = isSelected(card.getId)
    ? styles.content_container_selected
    : styles.content_container;
  const hoverBorderColor = {
    "--hover-card-border-color": CardTypesColorEnum[card.cardType][0].color,
  } as React.CSSProperties;
  const hoverCardTypeColor = {
    "--hover-card-type-color": CardTypesColorEnum[card.cardType][0].color,
  } as React.CSSProperties;
  const cardTypeColor =
    isSelected(card.getId) &&
    ({
      "--card-type-color": CardTypesColorEnum[card.cardType][0].color,
    } as React.CSSProperties);

  useEffect(() => {
    if (card) contentArray.push(card);
  }, [contentArray]);

  useEffect(() => {
    // Select newly created card
    if (card.getId && editing) {
      onClick(card.getId);
    }
  });

  useEffect(() => {
    // Scroll to selected card when it's not in viewport
    if (!isSelected(card.getId)) return;

    if (containerRef.current && !isInViewport(containerRef)) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isSelected(card.getId)]);

  useEffect(() => {
    if (!editing) return;
    Emitter.on("handleOnCancelCardBase", handleHideForm);
  }, [editing]);

  function handleHideForm(uid: string) {
    if (uid === card.cardUid) {
      setEditing(false);
    }
  }

  return (
    <div ref={containerRef}>
      <CardBoilerplate
        className={className}
        style={hoverBorderColor}
        shouldEdit={editing}
        title={TextDecodeUtils(card.title)}
        icon={
          <CardTypeIconComponent
            style={{ fontSize: 20, ...cardTypeColor, ...hoverCardTypeColor }}
            cardType={card.cardType}
          />
        }
        titleIcon={<CardTitleIconComponent card={card} />}
        detail={getDetail()}
        onClick={() => onClick(card.getId)}
        actions={getActions()}
      />
      <ContentContextActionsComponent ref={contentContextActionsRef} />
    </div>
  );

  function getDetail() {
    if (editing) {
      switch (card.cardType) {
        case CardTypesEnum.KNOWLEDGE_CARD:
          return <CreateKnowledgeCardForm cardContent={card} />;
        case CardTypesEnum.QUIZ:
          return <CreateQuizCardForm cardContent={card} />;
        case CardTypesEnum.MULTIPLE_CHOICE:
          return <CreateMultipleCardForm cardContent={card} />;
        case CardTypesEnum.SLIDER:
          return <CreateSliderCardForm cardContent={card} />;
        case CardTypesEnum.QUESTION:
          return <CreateQuestionCardForm cardContent={card} />;
        case CardTypesEnum.PICTURE_QUIZ:
          return <CreatePictureQuizCardForm cardContent={card} />;
        case CardTypesEnum.GENERIC_QUIZ:
          return <CreateGenericQuizCardForm cardContent={card} />;
      }
    } else {
      return <CardDetailComponent card={card} />;
    }
  }

  function getActions(): ContentActionProps[] {
    if (card.contentType !== ContentTypesEnum.CARD || editing) {
      return [];
    }

    if (
      cardsCountWithSameUid.length > 1 &&
      card.contentState === ContentStatesEnum.PUBLISHED
    ) {
      return [
        {
          icon: <ContextActionIconComponent action={"delete"} />,
          label: Capitalize(t("common.delete")),
          onClick: () => contentContextActionsRef.current?.deleteContent(card),
        },
        {
          icon: <ContextActionIconComponent action={"duplicate"} />,
          label: Capitalize(t("common.duplicate")),
          onClick: () =>
            contentContextActionsRef.current?.duplicateContent(card),
        },
      ];
    }

    return [
      {
        icon: <ContextActionIconComponent action={"delete"} />,
        label: Capitalize(t("common.delete")),
        onClick: () => contentContextActionsRef.current?.deleteContent(card),
      },
      {
        icon: <ContextActionIconComponent action={"duplicate"} />,
        label: Capitalize(t("common.duplicate")),
        onClick: () => contentContextActionsRef.current?.duplicateContent(card),
      },
      {
        icon: <ContextActionIconComponent action={"edit"} />,
        label: Capitalize(t("common.edit")),
        onClick: () => setEditing(true),
      },
    ];
  }
};

const mapStateToProps = (
  state: StoreInterface,
  ownProps: OwnProps,
): StateProps => ({
  cardsCountWithSameUid: selectCardsCountWithSameUidViaUid(
    state,
    ownProps.card.cardUid,
  ),
});

export default connect(mapStateToProps)(CardComponent);
