import React from "react";
import { Checkbox, Form } from "antd";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import GenericQuizCardModel from "../../../../core/models/content/card-types/generic-quiz/generic-quiz-card.model";

const GenericCardMeta = ({ card }: { card: GenericQuizCardModel }) => {
  const { t } = useTranslation();
  return (
    <Form style={{ pointerEvents: "none" }}>
      <Form.Item
        label={Capitalize(t("translations:form.items.show-explanation.label"))}
        initialValue={card.data.showFeedback}
      >
        <Checkbox checked={card.data.showFeedback} />
      </Form.Item>
      <Form.Item
        label={Capitalize(t("translations:form.items.is-practice.label"))}
        initialValue={card.data.isPractise}
      >
        <Checkbox checked={card.data.isPractise} />
      </Form.Item>
    </Form>
  );
};

export default GenericCardMeta;
