import { EffectType } from "../../../types/redux.type";
import { CreateInvitationInterface } from "../../../interfaces/create-invitation.interface";
import EffectUtility from "../../../utils/effect.utils";
import InvitationModel from "../../../models/invitation/invitation.model";
import InvitationEndpoints from "../../../api/invitation.endpoints";
import HttpUtility from "../../../utils/http.utils";

export default class InvitationEffect {
  /**
   * Get all Invitations via GET Request
   * @returns Promise InvitationModel[]
   */
  static getAllInvitations(): EffectType<InvitationModel[]> {
    const endpoint = InvitationEndpoints.overview;
    return EffectUtility.getToModel<InvitationModel[]>(
      InvitationModel,
      endpoint,
    );
  }

  /**
   * Create multiple Invitation via POST Request
   * @param res CreateInvitationInterface[]
   * @returns string
   */
  static createInvitations(
    res: CreateInvitationInterface[],
  ): EffectType<InvitationModel> {
    const endpoint = InvitationEndpoints.createBulk;
    return EffectUtility.postToModel<InvitationModel>(
      InvitationModel,
      endpoint,
      res,
    );
  }

  /**
   * Mark Invitations as Sent via POST Request
   * @param data { invitationUid: string }[]
   * @returns boolean
   */
  static markInvitationsAsSent(data: string[]): EffectType<InvitationModel[]> {
    const endpoint = InvitationEndpoints.sending;
    return HttpUtility.post(endpoint, data);
  }
}
