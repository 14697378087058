import React, { Key, useEffect, useRef, useState } from "react";
import { Button, Drawer, Input, Space, Table, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import {
  useGetCategories,
  useGetLearningPaths,
  useGetPlaybooks,
  useGetTags,
} from "../../../../core/api/primio/primioComponents";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { SorterResult } from "antd/lib/table/interface";
import { ColumnsType, TableProps } from "antd/lib/table";
import { FilterValue } from "antd/es/table/interface";
import onlyUnique from "../../../../core/utils/onlyUnique.util";
import styles from "./playbooks-select.module.css";
import i18next from "i18next";
import InlineCategoryManager from "../../inline-manager/inline-category-manager";
import InlineUserGroupAclManager from "../../inline-manager/inline-user-group-acl-manager";
import InlinePlaybookLearningPathManager from "../../inline-manager/inline-playbook-learning-path-manager";
import InlinePlaybookTagManager from "../../inline-manager/inline-playbook-tag-manager";
import ContentStateIcon from "../../content-state-icon/content-state-icon";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";

type Props = {
  playbookUids: string[];
  onChange?: (value: SelectedPlaybookUidsType[]) => void;
  onClose?: () => void;
  mode?: "multiple" | "single";
  filter?: (value: Playbook, index: number, array: Playbook[]) => boolean;
  openDrawerImmediately?: boolean;
};

export interface SelectedPlaybookUidsType {
  playbookUid: string;
  isShared?: boolean;
}

const PlaybooksSelectComponent = ({
  playbookUids,
  onChange,
  onClose,
  mode = "multiple",
  filter = () => true,
  openDrawerImmediately = false,
}: Props) => {
  const [t] = useTranslation();
  const { data: playbooks = [] } = useGetPlaybooks(
    {
      queryParams: { excludeMedia: true },
    },
    {
      select: (data) => data.filter(filter as any),
    },
  );
  const { data: learningPaths = [] } = useGetLearningPaths({});
  const { data: categories = [] } = useGetCategories({});
  const { data: tags = [] } = useGetTags({});

  const [selectedPlaybooks, setSelectedPlaybooks] = useState<Playbook[]>([]);
  const [displayPlaybooks, setDisplayPlaybooks] = useState<Playbook[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(openDrawerImmediately);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Playbook>>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [displayedRowKeys, setDisplayedRowKeys] = useState<Key[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groups, setGroups] = useState<string[]>([]);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [invisibleIndexes, setInvisibleIndexes] = useState<number[]>([]);

  useEffect(() => {
    setFilteredInfo({
      ...filteredInfo,
      title: [searchTerm],
    });
  }, [searchTerm]);

  useEffect(() => {
    if (!outerContainerRef.current) {
      return;
    }

    const containerPadding = 4;
    const itemMargin = 10;
    const containerWidth =
      outerContainerRef.current.clientWidth + containerPadding * 2;

    const itemSizes: number[] = [];

    for (let i = 0; i < outerContainerRef.current.children.length; i++) {
      const child = outerContainerRef.current.children[i];
      itemSizes.push(child.clientWidth + itemMargin);
    }

    const invisibleIndexes: number[] = [];

    itemSizes.forEach((width, index, array) => {
      const sliced = array.slice(0, index + 1);
      const sum = sliced.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );
      const isOverflowing = sum > containerWidth;
      if (isOverflowing) {
        invisibleIndexes.push(index);
      }
    });

    setInvisibleIndexes(invisibleIndexes);
  }, [outerContainerRef, selectedPlaybooks]);

  useEffect(() => {
    const groups: string[] = [];

    playbooks.forEach((playbook) => {
      groups.push(...playbook.userGroupAcl);
    });

    setGroups(groups.filter(onlyUnique));

    if (selectedPlaybooks.length > 0) {
      return;
    }

    if (playbooks.length > 0 && playbookUids.length > 0) {
      const selectedPlaybooks = playbooks.filter((playbook) =>
        playbookUids.includes(playbook.playbookUid),
      );
      setSelectedPlaybooks(selectedPlaybooks);
      setDisplayPlaybooks(selectedPlaybooks);
      setSelectedRowKeys(selectedPlaybooks.map((p) => p.playbookUid));
      setDisplayedRowKeys(selectedPlaybooks.map((p) => p.playbookUid));
    }
  }, [playbooks, playbookUids]);

  const getCategoriesFromPlaybook = (playbookUid: string) =>
    categories.filter((category) =>
      category.playbooks
        .map((playbook) => playbook.playbookUid)
        .includes(playbookUid),
    );

  const columns: ColumnsType<Playbook> = [
    {
      key: "title",
      title: Capitalize(t("form.items.name.label")),
      sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
      filteredValue: filteredInfo.title,
      onFilter: (value, record) =>
        record.title
          .toLowerCase()
          .includes(value.toLocaleString().toLowerCase() as string),
      sorter: (a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (b.title > a.title) {
          return -1;
        }
        return 0;
      },

      render: function renderText(_, data) {
        return <Typography.Text>{data.title}</Typography.Text>;
      },
    },
    {
      key: "contentState",
      title: Capitalize(t("common:state")),
      width: 80,
      filteredValue: filteredInfo.contentState || null,
      filters: [
        {
          text: Capitalize(i18next.t("content-state.PUBLISHED")),
          value: ContentStatesEnum.PUBLISHED,
        },
        {
          text: Capitalize(i18next.t("content-state.DRAFT")),
          value: ContentStatesEnum.DRAFT,
        },
      ],
      onFilter: (value, record) => record.contentState === value,
      render: (_, data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ContentStateIcon
            contentState={data.contentState as ContentStatesEnum}
          />
        </div>
      ),
    },
    {
      key: "categories",
      title: Capitalize(t("containers.categories.key_plural")),
      filterSearch: true,
      filteredValue: filteredInfo.categories || null,
      filters: categories.map((category) => ({
        text: category.title,
        value: category.categoryUid,
      })),
      onFilter: (categoryUid, record) => {
        const categories = getCategoriesFromPlaybook(record.playbookUid);

        if (categories.length === 0 || typeof categoryUid !== "string") {
          return false;
        }

        return !!categories.find((c) => c.categoryUid === categoryUid);
      },
      render: function renderText(_, data) {
        const categories = getCategoriesFromPlaybook(data.playbookUid);
        return (
          <InlineCategoryManager
            categories={categories}
            playbookUid={data.playbookUid}
            disabled
          />
        );
      },
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      filterSearch: true,
      filteredValue: filteredInfo.userGroupAcl || null,
      filters: groups.map((group) => ({
        text: group,
        value: group,
      })),
      onFilter: (value, record) =>
        record.userGroupAcl.includes(value as string),
      render: (_, data) => (
        <InlineUserGroupAclManager
          userGroups={data.userGroupAcl}
          playbookUid={data.playbookUid}
          disabled
        />
      ),
    },
    {
      key: "learningPaths",
      title: Capitalize(i18next.t("containers.learning-path.key_plural")),
      filterSearch: true,
      filteredValue: filteredInfo.learningPaths || null,
      filters: learningPaths.map((learningPath) => ({
        text: learningPath.title,
        value: learningPath.learningPathUid,
      })),
      onFilter: (value, record) => {
        const { learningPaths = [] } = record;
        return learningPaths
          .map((l) => l.learningPathUid)
          .includes(value as string);
      },
      render: (_, data) => {
        const { learningPaths = [] } = data;

        if (learningPaths.length === 0) {
          return null;
        }

        return (
          <InlinePlaybookLearningPathManager learningPaths={learningPaths} />
        );
      },
    },
    {
      key: "tags",
      title: "Tags",
      filteredValue: filteredInfo.tags || null,
      filters: tags.map((tag) => ({
        text: <Tag color={tag.color}>{tag.name}</Tag>,
        value: tag.tagUid,
      })),
      onFilter: (tagUid, playbook) =>
        playbook.tags.map((t) => t.tagUid).includes(tagUid as string),
      width: 150,
      render: (_, data) => (
        <InlinePlaybookTagManager
          tags={data.tags ?? []}
          playbookUid={data.playbookUid}
          disabled
        />
      ),
    },
  ];

  const handleChange: TableProps<Playbook>["onChange"] = (
    pagination,
    filters,
    sorter,
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<Playbook>);
  };

  function handleOnClose() {
    onClose?.();
    setIsDrawerOpen(false);
    setSelectedRowKeys(displayedRowKeys);
    setSelectedPlaybooks(displayPlaybooks);
  }

  function handleOnChange(value: Playbook[]) {
    setDisplayPlaybooks(value);
    setDisplayedRowKeys(selectedRowKeys);

    if (!onChange) {
      return;
    }

    const results = value.map((playbook) => {
      return {
        playbookUid: playbook.playbookUid,
        isShared: !!playbook.apiKeyClientUid,
      };
    });

    return onChange(results);
  }

  return (
    <>
      <div
        className={styles.PlaybookPicker}
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <div
          ref={outerContainerRef}
          className={styles.PlaybookPicker_InnerContainer}
        >
          {displayPlaybooks.length === 0 && (
            <p>
              {Capitalize(
                t("translations:form.placeholders.select_x", {
                  x: t("translations:content.playbook.key"),
                }),
              )}
            </p>
          )}

          {displayPlaybooks.map((playbook, index) => (
            <span key={index}>{playbook.title}</span>
          ))}
        </div>
        {invisibleIndexes.length > 0 && (
          <div className={styles.PlaybookPicker_OverflowLabel}>
            <span>+ {invisibleIndexes.length} ...</span>
          </div>
        )}
      </div>

      <Drawer
        open={isDrawerOpen}
        width={1000}
        onClose={handleOnClose}
        title={`${Capitalize(
          t("translations:form.placeholders.select_x", {
            x: t("translations:content.playbook.key"),
          }),
        )} ${selectedPlaybooks.length > 0 ? `(${selectedPlaybooks.length})` : ""}`}
        extra={
          <Space>
            <Input
              placeholder={Capitalize(t("translations:common.search"))}
              onChange={(e) => setSearchTerm(e.target.value)}
              allowClear
            />
            <Button
              type={"primary"}
              onClick={() => {
                handleOnChange([...selectedPlaybooks]);
                handleOnClose();
              }}
            >
              {Capitalize(t("translations:common.submit"))}
            </Button>
          </Space>
        }
        bodyStyle={{
          position: "relative",
          padding: 0,
        }}
      >
        <Table
          dataSource={playbooks}
          className={"custom-fixed-header"}
          onChange={handleChange}
          scroll={{ y: 900 }}
          pagination={false}
          rowKey={(playbook) => playbook.playbookUid}
          size={"small"}
          columns={columns}
          showSorterTooltip={false}
          rowSelection={{
            type: mode === "multiple" ? "checkbox" : "radio",
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              setSelectedPlaybooks(selectedRows);
            },
          }}
        />
      </Drawer>
    </>
  );
};

export default PlaybooksSelectComponent;
