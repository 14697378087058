import { FilterValue } from "antd/es/table/interface";
import { SorterResult } from "antd/lib/table/interface";
import { Playbook } from "../../../../core/api/primio/primioSchemas";
import { ColumnsType } from "antd/lib/table";
import { Capitalize } from "../../../../core/utils/helper.utils";
import i18next from "i18next";
import { Space, Tag, Typography } from "antd";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import ContentStateIcon from "../../../components/content-state-icon/content-state-icon";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../../constants/routes/app.route";
import React from "react";
import {
  useGetApiKeyClients,
  useGetCategories,
  useGetLearningPaths,
  useGetTags,
  useGetUserGroups,
} from "../../../../core/api/primio/primioComponents";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../../core/enums/user-role.enum";
import ContentActionComponent from "../../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../../components/icons/context-action-icon.component";
import InlineCategoryManager from "../../../components/inline-manager/inline-category-manager";
import InlineUserGroupAclManager from "../../../components/inline-manager/inline-user-group-acl-manager";
import InlinePlaybookTagManager from "../../../components/inline-manager/inline-playbook-tag-manager";
import InlinePlaybookLearningPathManager from "../../../components/inline-manager/inline-playbook-learning-path-manager";

export default function useCreatePlaybookColumnType() {
  const { data: categories = [] } = useGetCategories({});
  const { data: userGroups = [] } = useGetUserGroups({
    queryParams: { shouldExcludeUsers: true },
  });
  const { data: apiKeyClients = [] } = useGetApiKeyClients({});
  const { data: learningPaths = [] } = useGetLearningPaths({});
  const { data: tags = [] } = useGetTags({});
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);

  const canEditPlaybook = userRole !== UserRoleEnum.GROUP_ADMIN;

  const create = (
    filteredInfo: Record<string, FilterValue | null>,
    sortedInfo: SorterResult<Playbook>,
  ): ColumnsType<Playbook> => {
    const columns: ColumnsType<Playbook> = [
      {
        key: "contentState",
        title: Capitalize(i18next.t("form.items.name.label")),
        sortOrder:
          sortedInfo.columnKey === "contentState" ? sortedInfo.order : null,
        filteredValue: filteredInfo.contentState || null,
        filters: [
          {
            text: Capitalize(i18next.t("content-state.PUBLISHED")),
            value: ContentStatesEnum.PUBLISHED,
          },
          {
            text: Capitalize(i18next.t("content-state.DRAFT")),
            value: ContentStatesEnum.DRAFT,
          },
        ],
        width: 200,
        onFilter: (value, record) => record.contentState === value,
        sorter: (a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (b.title > a.title) {
            return -1;
          }
          return 0;
        },
        render: function renderText(_, data) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Typography.Text>{data.title}</Typography.Text>
              </div>
              <div style={{ marginLeft: 4 }}>
                <ContentStateIcon
                  contentState={data.contentState as ContentStatesEnum}
                />
              </div>
            </div>
          );
        },
      },
      {
        key: "categories",
        title: Capitalize(i18next.t("containers.categories.key_plural")),
        filterSearch: true,
        filteredValue: filteredInfo.categories || null,
        filters: categories.map((category) => ({
          text: category.title,
          value: category.categoryUid,
        })),
        width: 150,
        render: (_, playbook: Playbook) => (
          <InlineCategoryManager
            categories={playbook.categories || []}
            playbookUid={playbook.playbookUid}
            disabled={!canEditPlaybook}
          />
        ),
      },
      {
        key: "userGroupAcl",
        title: Capitalize(i18next.t("containers.user-groups.key_plural")),
        filterSearch: true,
        filteredValue: filteredInfo.userGroupAcl || null,
        filters: userGroups.map((group) => ({
          text: group.groupType.GroupName,
          value: group.groupType.GroupName,
        })),
        width: 150,
        render: (_, data) => (
          <InlineUserGroupAclManager
            userGroups={data.userGroupAcl}
            playbookUid={data.playbookUid}
            disabled={!canEditPlaybook}
          />
        ),
      },
      {
        key: "learningPaths",
        title: Capitalize(i18next.t("containers.learning-path.key_plural")),
        filterSearch: true,
        filteredValue: filteredInfo.learningPaths || null,
        filters: learningPaths.map((learningPath) => ({
          text: learningPath.title,
          value: learningPath.learningPathUid,
        })),
        width: 150,
        render: (_, data) => {
          const { learningPaths = [] } = data;

          if (learningPaths.length === 0) {
            return null;
          }

          return (
            <InlinePlaybookLearningPathManager learningPaths={learningPaths} />
          );
        },
      },
      {
        key: "apiKeyClientUid",
        title: Capitalize(i18next.t("common.source")),
        sortOrder:
          sortedInfo.columnKey === "apiKeyClientUid" ? sortedInfo.order : null,
        width: 150,
        sorter: (a, b) => {
          if (!a.apiKeyClientUid || !b.apiKeyClientUid) {
            return -1;
          }
          if (a.apiKeyClientUid > b.apiKeyClientUid) {
            return 1;
          }
          if (b.apiKeyClientUid > a.apiKeyClientUid) {
            return -1;
          }
          return 0;
        },
        render: function renderText(_, data: Playbook) {
          const server = apiKeyClients.find(
            (a) => a.apiKeyClientUid === data.apiKeyClientUid,
          );

          if (!server) {
            return;
          }

          const displayName = server.server.split(".")[1];

          return <Typography.Text>{Capitalize(displayName)}</Typography.Text>;
        },
      },
      {
        key: "tags",
        title: "Tags",
        filteredValue: filteredInfo.tags || null,
        filters: tags.map((tag) => ({
          text: <Tag color={tag.color}>{tag.name}</Tag>,
          value: tag.tagUid,
        })),
        width: 150,
        render: (_, data) => (
          <InlinePlaybookTagManager
            tags={data.tags ?? []}
            playbookUid={data.playbookUid}
            disabled={!canEditPlaybook || !!data.apiKeyClientUid}
          />
        ),
      },
      {
        key: "action",
        width: 1,
        render: function showActions(_, data) {
          if (data.apiKeyClientUid) {
            return null;
          }

          const url = RoutesEnum.EDIT_MODE_PLAYBOOK as string;
          return (
            <Space size={"middle"} style={{ float: "right" }}>
              <Link to={url.replace(":playbookUid", data.playbookUid)}>
                <ContentActionComponent
                  icon={
                    <ContextActionIconComponent
                      action={"edit"}
                      style={{ color: "var(--primary-color)" }}
                    />
                  }
                />
              </Link>
            </Space>
          );
        },
      },
    ];

    if (
      userRole === UserRoleEnum.PRIMIO_SUPPORT ||
      userRole === UserRoleEnum.SYS_ADMIN
    ) {
      columns.unshift({
        key: "playbookUid",
        title: "Id",
        width: 1,
        render: (playbook: Playbook) => (
          <Typography.Text
            copyable={{
              text: playbook.playbookUid,
              tooltips: playbook.playbookUid.substring(0, 5) + "...",
            }}
          />
        ),
      });
    }

    return columns;
  };

  return { create };
}
