export enum RoutesEnum {
  HOME = `/`,
  PLAYBOOKS = `/modules`,
  CATEGORIES = `/categories`,
  CATEGORIES_DETAIL = `/categories/:uid`,
  EDIT_MODE = `/edit`,
  EDIT_MODE_PLAYBOOK = `/edit/playbook/:playbookUid`,
  EDIT_MODE_CHAPTER = `/edit/playbook/:playbookUid/chapter/:chapterUid`,
  EDIT_MODE_COLLECTION = `/edit/playbook/:playbookUid/chapter/:chapterUid/collection/:collectionUid`,
  EDIT_MODE_CARD = `/edit/playbook/:playbookUid/chapter/:chapterUid/collection/:collectionUid/card/:cardUid`,
  //
  IMPORT_PLAYBOOKS = "/modules/import",
  EXPORT_PLAYBOOKS = "/modules/export",
  //
  LEARNING_PATH = `/learning-paths`,
  LEARNING_PATH_DETAIL_LEGACY = `/learning-paths/:uid/legacy`,
  LEARNING_PATH_DETAIL = `/learning-paths/:uid/`,
  LEARNING_PATH_DETAIL_CONTENT = `/learning-paths/:uid/content/:contentUid`,
  LEARNING_PATH_DETAIL_REPORT = `/learning-paths/:uid/report`,
  //
  QR_CODE = `/qr`,
  QR_CODE_DETAIL = `/qr/:uid`,
  //
  ACTIVITY = `/activity`,
  PUBLISHED_CONTENT = `/activity/published-content`,
  PUBLISHED_CONTENT_USER_PROGRESS = `/activity/published-content/user-progress`,
  PUBLISHED_CONTENT_USER_PROGRESS_PLAYBOOK = `/activity/published-content/user-progress/playbook/:playbookUid`,
  PUBLISHED_CONTENT_USER_PROGRESS_CHAPTER = `/activity/published-content/user-progress/playbook/:playbookUid/chapter/:chapterUid`,
  PUBLISHED_CONTENT_USER_PROGRESS_COLLECTION = `/activity/published-content/user-progress/playbook/:playbookUid/chapter/:chapterUid/collection/:collectionUid`,
  USER_ACTIVITY = `/activity/user-activity`,
  //
  MODULE_DETAIL = `/activity/published-content/playbook/:playbookUid`,
  CHAPTER_DETAIL = `/activity/published-content/playbook/:playbookUid/chapter/:chapterUid`,
  COLLECTION_DETAIL = `/activity/published-content/playbook/:playbookUid/chapter/:chapterUid/collection/:collectionUid`,
  CARD_DETAIL = `/activity/published-content/playbook/:playbookUid/chapter/:chapterUid/collection/:collectionUid/card/:cardUid`,
  //
  USERS = `/users`,
  USER_DETAIL = `/users/:username`,
  USER_GROUPS = `/user-groups`,
  USER_GROUP_DETAILS = `/user-groups/:title`,
  //
  INVITATIONS = "/invitations",
  //
  NOTIFICATIONS = `/notifications`,
  NOTIFICATIONS_DETAIL = `/notifications/:uid`,
  //
  LOGIN = `/login`,
  NEW_PASSWORD_CHALLENGE = `/new-password`,
  FORGET_PASSWORD = `/forget-password`,
  CHANGE_PASSWORD = `/change-password`,
  //
  SHARED_CONTENT = `/shared-content`,
  SHARED_CONTENT_DETAIL = `/shared-content/:key`,
  SHARED_CONTENT_CLIENT = `/shared-content/client`,
  //
  FEATURE_FLAGS = "/feature-flags",
  //
  ANALYTICS = "/analytics",
}
