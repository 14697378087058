import React, { useEffect, useState } from "react";
import { RoutesInvitationsEnum } from "../../../constants/routes/invitations.route";
import { connect, useDispatch, useSelector } from "react-redux";
import { InvitationState } from "../../../core/types/invitation.types";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { Button, Modal, notification } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { selectRawErrors } from "../../../core/redux/selectors/error/error.selector";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { selectInvitations } from "../../../core/redux/selectors/invitation/invitation.selector";
import PageLayoutComponent from "../../components/page-layout/page-layout.component";
import EmptyStateScreen from "../../screens/empty-state.screen";
import CreatedInvitationsScreen from "../../screens/invitations/created-invitations.screen";
import PendingInvitationsScreen from "../../screens/invitations/pending-invitations.screen";
import CompletedInvitationsScreen from "../../screens/invitations/completed-invitations.screen";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import useQuery from "../../../core/hooks/useQuery";
import HttpErrorResponseModel from "../../../core/models/http-error-response.model";
import MenuItemInterface from "../../../core/interfaces/menu-item.interface";
import InvitationAction from "../../../core/redux/stores/invitation/invitation.action";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import colors from "../../styles/colors";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import { CLIENT_NAME } from "../../../constants/config/env.config";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";
import { useDeleteUserInvitation } from "../../../core/api/primio/primioComponents";

interface StateProps {
  invitations: InvitationModel[];
  requestingSendInvitation: boolean;
  requesting: boolean;
  error: HttpErrorResponseModel;
}

type Props = StateProps;

const InvitationsContainer = ({
  invitations,
  requestingSendInvitation,
}: Props) => {
  const { t } = useTranslation();
  const [invitationStatusFilter, setInvitationStatusFilter] =
    useState<InvitationState>(InvitationState.CREATED);
  const [api, contextHolder] = notification.useNotification();
  useState<boolean>(false);
  const [invitationAmount, setInvitationAmount] = useState<InvitationModel[]>(
    [],
  );
  const dispatch = useDispatch();
  const query = useQuery();
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);
  const hasFailedInvites =
    invitations.filter((i) => i.state === InvitationState.FAILED).length !== 0;
  const { openModal } = useModal();

  const { mutateAsync: deleteUserInvitationAsync } = useDeleteUserInvitation({
    onSuccess: () => dispatch(InvitationAction.getAllInvitations()),
  });

  useEffect(() => {
    dispatch(InvitationAction.getAllInvitations());
  }, []);

  useEffect(() => {
    if (requestingSendInvitation) {
      api["success"]({
        message: "",
        description: Capitalize(
          t("errors.warnings.sent", {
            container:
              invitationAmount.length > 1
                ? t("containers.invitations.key_plural")
                : t("containers.invitations.key"),
          }),
        ),
        style: {
          backgroundColor: "rgba(246, 255, 237, 1)",
        },
        placement: "top",
      });
    }
  }, [requestingSendInvitation]);

  const state: InvitationState | null = query.get(
    "state",
  ) as InvitationState | null;
  if (state) {
    if (state !== invitationStatusFilter) {
      setInvitationStatusFilter(state);
    }
  } else {
    if (invitationStatusFilter !== InvitationState.CREATED) {
      setInvitationStatusFilter(InvitationState.CREATED);
    }
  }

  const pageTitle = {
    CREATED: Capitalize(t("containers.invitations.title_unsent")),
    FAILED: Capitalize(t("containers.invitations.title_failed")),
    PENDING: Capitalize(t("containers.invitations.title_sent")),
    COMPLETED: Capitalize(t("containers.invitations.title_accepted")),
  };

  const menuItems: MenuItemInterface[] = [
    {
      title: `${Capitalize(t("screens.invitation.state.CREATED"))} (${
        invitations.filter((i) => i.state === InvitationState.CREATED).length
      })`,
      route: RoutesInvitationsEnum.CREATED,
    },
    {
      title: `${Capitalize(t("screens.invitation.state.PENDING"))} (${
        invitations.filter((i) => i.state === InvitationState.PENDING).length
      })`,
      route: RoutesInvitationsEnum.PENDING,
    },
    {
      title: `${Capitalize(t("screens.invitation.state.COMPLETED"))} (${
        invitations.filter((i) => i.state === InvitationState.COMPLETED).length
      })`,
      route: RoutesInvitationsEnum.COMPLETED,
    },
  ];

  if (hasFailedInvites)
    menuItems.splice(1, 0, {
      title: `${Capitalize(t("screens.invitation.state.FAILED"))} (${
        invitations.filter((i) => i.state === InvitationState.FAILED).length
      })`,
      route: RoutesInvitationsEnum.FAILED,
    });

  const filteredInvitations = (): InvitationModel[] => {
    if (invitations.length === 0) {
      return [];
    }

    if (!invitationStatusFilter) {
      return invitations;
    }

    return invitations.filter((i) => i.state === invitationStatusFilter);
  };

  return (
    <PageLayoutComponent
      menuItems={menuItems}
      title={"invitations"}
      customTitle={pageTitle[invitationStatusFilter]}
      onPress={() => onShowModal()}
      secondaryButton={renderSecondaryButton()}
    >
      {contextHolder}
      {!invitations || filteredInvitations().length === 0 ? (
        <EmptyStateScreen route={"invitations"} onPress={() => onShowModal()} />
      ) : invitationStatusFilter === InvitationState.CREATED ? (
        <CreatedInvitationsScreen
          invitations={filteredInvitations()}
          onSend={handleOnSelectSendInvitations}
          onSendAll={handleOnSendAllInvitationModal}
          onDelete={handleOnDeleteInvitationModal}
          onEdit={onShowModal}
        />
      ) : invitationStatusFilter === InvitationState.PENDING ? (
        <PendingInvitationsScreen
          invitations={filteredInvitations()}
          onSend={handleOnSelectSendInvitations}
          onSendAll={handleOnSendAllInvitationModal}
          onDelete={handleOnDeleteInvitationModal}
        />
      ) : invitationStatusFilter === InvitationState.FAILED ? (
        <CreatedInvitationsScreen
          invitations={filteredInvitations()}
          onSend={handleOnSelectSendInvitations}
          onSendAll={handleOnSendAllInvitationModal}
          onDelete={handleOnDeleteInvitationModal}
          onEdit={onShowModal}
          failed
        />
      ) : (
        <CompletedInvitationsScreen invitations={filteredInvitations()} />
      )}
    </PageLayoutComponent>
  );

  function renderSecondaryButton() {
    if (
      userRole === UserRoleEnum.GROUP_ADMIN ||
      invitationStatusFilter === InvitationState.FAILED
    ) {
      return undefined;
    }

    return (
      <Button
        type={"primary"}
        ghost
        onClick={() => openModal(ModalNames.IMPORT_INVITATIONS)}
        icon={<UploadOutlined />}
      >
        {Capitalize(
          t("common.import_x", {
            item: Capitalize(t(`containers.invitations.key_plural`)),
          }),
        )}
      </Button>
    );
  }

  function onShowModal(invitation?: InvitationModel) {
    openModal(ModalNames.CREATE_INVITATION, { invitation });
  }

  function handleOnSelectSendInvitations(data: InvitationModel[]) {
    setInvitationAmount(data);
    dispatch(InvitationAction.markInvitationsAsSent(data));
  }

  function handleOnSendAllInvitationModal(data: InvitationModel[]) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.send_to", {
          x: data.length,
          env: CLIENT_NAME,
        }),
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(
        t("common.send_x", { item: t("containers.invitations.key") }),
      ),
      onOk() {
        dispatch(InvitationAction.markInvitationsAsSent(data));
      },
    });
  }

  function handleOnDeleteInvitationModal(data: InvitationModel) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete", { field: t("containers.invitations.key") }),
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      async onOk() {
        await deleteUserInvitationAsync({
          pathParams: {
            invitationUid: data.invitationUid,
          },
        });
      },
    });
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  invitations: selectInvitations(state),
  requestingSendInvitation: selectRequesting(state, [
    InvitationAction.REQUEST_MARK_INVITATIONS_AS_SENT,
  ]),
  requesting: selectRequesting(state, [
    InvitationAction.REQUEST_INVITATIONS,
    InvitationAction.REQUEST_DELETE_INVITATION,
  ]),
  error: selectRawErrors(state, [
    InvitationAction.REQUEST_INVITATIONS_FINISHED,
    InvitationAction.REQUEST_DELETE_INVITATION_FINISHED,
  ]),
});

export default connect(mapStateToProps)(InvitationsContainer);
