import { CardTypesEnum } from "../../../../enums/card-types.enum";
import BaseCardModel, { BaseCardResponse } from "../../base-card.model";
import GenericQuizDataModel, {
  GenericQuizDataResponse,
} from "./generic-quiz-card-data.model";

export interface GenericQuizCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.GENERIC_QUIZ;
  data: GenericQuizDataResponse;
}

export default class GenericQuizCardModel extends BaseCardModel {
  cardType: CardTypesEnum.GENERIC_QUIZ;
  data: GenericQuizDataModel;

  constructor(genericQuiz: GenericQuizCardResponse) {
    super(genericQuiz);
    this.cardType = CardTypesEnum.GENERIC_QUIZ;
    this.data = new GenericQuizDataModel(genericQuiz.data);
  }
}
