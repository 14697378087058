import React, { useEffect, useState } from "react";
import {
  fetchGetGroupsForUser,
  fetchGetProgressForUser,
  fetchGetUserLearningPaths,
  useGetLearningPaths,
  useGetUsers,
} from "../../../core/api/primio/primioComponents";
import Moment from "moment";
import { CognitoUser } from "../../../core/api/primio/primioSchemas";
import { useQuery } from "@tanstack/react-query";
import { useToken } from "../../../core/providers/auth.provider";
import { getTrainingUserProgressSummary } from "./cohort.analytics";

async function getAllUserProgress({
  users,
  token,
}: {
  users: CognitoUser[];
  token: string;
}) {
  const result: any[] = [];

  for (const user of users) {
    const userGroups = await fetchGetGroupsForUser({
      headers: {
        authorization: `Bearer ${token}`,
      },
      pathParams: { username: user.username },
    });
    const progress = await fetchGetProgressForUser({
      headers: {
        authorization: `Bearer ${token}`,
      },
      pathParams: { username: user.username },
    });
    const learningPathProgress = await fetchGetUserLearningPaths({
      headers: {
        authorization: `Bearer ${token}`,
      },
      pathParams: { userUid: user.sub },
    });
    result.push({ user, userGroups, progress, learningPathProgress });
  }

  return result;
}

const SleepingUsersAnalytics = () => {
  const token = useToken();
  const { data: users = [], isFetching: isFetchingUsers } = useGetUsers({});
  const { data: learningPaths = [], isFetching: isFetchingLearningPaths } =
    useGetLearningPaths({});

  const [inactiveUsers, setInactiveUsers] = useState<CognitoUser[]>([]);
  const [inactiveUsersWithExpoTokens, setInactiveUsersWithExpoTokens] =
    useState<CognitoUser[]>([]);
  const [usersWithStartedLearningPaths, setUsersWithStartedLearningPaths] =
    useState(0);

  const {
    data: inactiveUsersProgress = [],
    isFetching: isFetchingInactiveUsersProgress,
  } = useQuery({
    queryFn: async () =>
      getAllUserProgress({ users: inactiveUsers, token: token ?? "" }),
    enabled: inactiveUsers.length > 0,
  });

  useEffect(() => {
    setInactiveUsers(
      users.filter(
        (u) =>
          u.lastActiveAt && Moment().diff(Moment(u.lastActiveAt), "weeks") > 2,
      ),
    );
  }, [users]);

  useEffect(() => {
    setInactiveUsersWithExpoTokens(
      inactiveUsers.filter((u) => u.expoTokens.length > 0),
    );
  }, [inactiveUsers]);

  useEffect(() => {
    let usersWithStartedLearningPaths = 0;
    if (inactiveUsersProgress.length === 0 || learningPaths.length === 0) {
      setUsersWithStartedLearningPaths(0);
      return;
    }

    inactiveUsersProgress.forEach((user) => {
      const progress = user.progress.users[user.user.sub];

      const availableLearningPaths = learningPaths.filter((learningPath) => {
        if (learningPath.scheduledFor) {
          const scheduledFor = new Date(learningPath.scheduledFor);
          if (scheduledFor > new Date()) {
            return false;
          }
        }

        if (!learningPath.userGroups || learningPath.userGroups.length === 0) {
          return true;
        }

        return learningPath.userGroups.some((group) =>
          user.userGroups.includes(group),
        );
      });

      const trainingUserProgressSummary = getTrainingUserProgressSummary(
        progress.progress,
        availableLearningPaths,
        user.learningPathProgress,
      );

      if (trainingUserProgressSummary.startedLearningPathUids.length > 0) {
        usersWithStartedLearningPaths++;
      }
    });

    setUsersWithStartedLearningPaths(usersWithStartedLearningPaths);
  }, [inactiveUsersProgress, learningPaths]);

  if (isFetchingUsers || isFetchingLearningPaths) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <p>Amount of users: {users.length}</p>
      <p>
        Amount of users who are more then 2 weeks inactive:{" "}
        {inactiveUsers.length}
      </p>
      <p>
        Amount of users with started learning paths:{" "}
        {isFetchingInactiveUsersProgress
          ? "Loading..."
          : usersWithStartedLearningPaths}
      </p>
      <p>
        Amount of inactive users with expo push tokens:{" "}
        {inactiveUsersWithExpoTokens.length}
      </p>
    </div>
  );
};

export default SleepingUsersAnalytics;
