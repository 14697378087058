import React, { useEffect, useState } from "react";
import GenericQuizCardModel from "../../../../core/models/content/card-types/generic-quiz/generic-quiz-card.model";
import { disableButton } from "../../../../core/utils/media-disable-button.utils";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import MediaModel from "../../../../core/models/media/media.model";
import { useDispatch } from "react-redux";
import GenericQuizOptionModel from "../../../../core/models/content/card-types/generic-quiz/generic-quiz-option.model";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import CreateOptionContainerComponent from "../option-card/create-option-container.component";
import FormQuestionField from "../../form-builder/components/form-question.field";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import MediaUploadSelectComponent from "../../media-upload/media-upload-select.component";
import appConfig from "../../../../constants/config/app.config";
import { CreateOptionInterface } from "../option-card/create-option.form";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { MediaTypesEnum } from "../../../../core/enums/media-providers.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import CreateGenericQuizCardModel from "../../../../core/models/create-content/card-types/create-generic-quiz-card.model";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { Form } from "antd";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";

const CreateGenericQuizCardForm = ({
  cardContent,
}: {
  cardContent: GenericQuizCardModel;
}) => {
  const { t } = useTranslation();
  const [media, setMedia] = useState<MediaModel | undefined>(
    cardContent?.media ? cardContent.media[0] : undefined,
  );
  const [question, setQuestion] = useState(
    TranslateDefaults(cardContent.data.question),
  );
  const [options, setOptions] = useState(cardContent.data.options);
  const [showExplanation, setShowExplanation] = useState(
    cardContent.data.showFeedback,
  );
  const [isPractise, setIsPractise] = useState(cardContent.data.isPractise);

  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const isCreating = cardContent === undefined;
  const isEditing = !isCreating;

  const maxOptions =
    options.length >=
    (media?.mediaUid && media.mediaUid.length > 0
      ? appConfig.quizWithMediaMaxOptions
      : appConfig.quizMaxOptions);
  const minOptions = options.length > 1;

  useEffect(() => {
    const isValid = options.map((item) => item.content.length === 0);
    setIsDisabled(isValid.includes(true));
  }, [options]);

  const handleOnFinish = (
    data: CreateCardInterface,
    isPressedButton?: ContentButtonStatesEnum,
  ) => {
    const genericQuizCard = new CreateGenericQuizCardModel({
      ...data,
      collectionUid: cardContent.collectionUid,
      cardType: CardTypesEnum.GENERIC_QUIZ,
      title: question,
      description:
        media && media.mediaType !== MediaTypesEnum.NO_MEDIA
          ? ""
          : data.description,
      mediaUids: media?.mediaUid ? [media.mediaUid] : data.mediaUids,
      data: {
        question,
        isPractise,
        showFeedback: showExplanation,
        options,
      },
    });

    if (isCreating) {
      dispatch(
        ContentAction.createContent(ContentTypesEnum.CARD, genericQuizCard),
      );
    }

    if (isEditing && cardContent) {
      if (
        isPressedButton &&
        cardContent.contentState === ContentStatesEnum.PUBLISHED
      ) {
        switch (isPressedButton) {
          case ContentButtonStatesEnum.CREATE:
            dispatch(
              ContentAction.updateContent(
                ContentTypesEnum.CARD,
                cardContent,
                genericQuizCard,
              ),
            );
            break;
          case ContentButtonStatesEnum.UPDATE:
            cardContent.version++;
            dispatch(
              ContentAction.updateAndPublishCard(cardContent, genericQuizCard),
            );
            break;
        }
      } else {
        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.CARD,
            cardContent,
            genericQuizCard,
          ),
        );
      }
    }
  };

  const handleOnChangeOption = (
    index: number,
    value: CreateOptionInterface,
  ) => {
    const newOptions = [...options] as GenericQuizOptionModel[];

    newOptions[index].content = value.content;
    if (value.explanation !== undefined) {
      newOptions[index].explanation = value.explanation;
    }
    if (value.isCorrect !== undefined) {
      newOptions[index].isCorrect = value.isCorrect;
    }

    setOptions([...newOptions] as GenericQuizOptionModel[]);
  };

  const handleOnAddOption = () => {
    if (maxOptions) {
      return;
    }

    const newOption = new GenericQuizOptionModel({
      content: "",
      isCorrect: false,
      sort: 0,
      explanation: "",
    });

    setOptions([...options, newOption] as GenericQuizOptionModel[]);
  };

  const handleSetMedia = (media: MediaModel) => {
    if (
      options.length > appConfig.quizWithMediaMaxOptions &&
      media?.mediaUid &&
      media?.mediaUid.length > 0
    ) {
      const newOptions = cardContent?.data.options ?? [];
      if (newOptions && newOptions?.length > appConfig.quizWithMediaMaxOptions)
        newOptions?.pop();
      setOptions(newOptions);
    }
    setMedia(media);
  };

  return (
    <CreateCardBaseForm
      cardContent={cardContent}
      disabledButton={!question || isDisabled || disableButton(media)}
      disabledFields={!!(media && media.mediaUid)}
      hideFields={true}
      onSubmit={handleOnFinish}
    >
      <>
        <MediaUploadSelectComponent
          media={media}
          onChange={handleSetMedia}
          contentType={ContentTypesEnum.CARD}
        />
        <FormQuestionField
          question={question}
          setQuestion={setQuestion}
          maxLength={250}
        />

        <Form.Item
          initialValue={showExplanation}
          labelAlign={"left"}
          name={"showFeedback"}
          label={Capitalize(
            t("translations:form.items.show-explanation.label"),
          )}
        >
          <Checkbox
            checked={showExplanation}
            onChange={() => setShowExplanation(!showExplanation)}
          />
        </Form.Item>
        <Form.Item
          initialValue={isPractise}
          labelAlign={"left"}
          name={"isPractise"}
          label={Capitalize(t("translations:form.items.is-practice.label"))}
        >
          <Checkbox
            checked={isPractise}
            onChange={() => setIsPractise(!isPractise)}
          />
        </Form.Item>

        <div
          style={{
            height: 1,
            backgroundColor: "rgba(0,0,0,0.15",
            marginBottom: "2.5rem",
          }}
        />

        {options && (
          <CreateOptionContainerComponent
            options={options as GenericQuizOptionModel[]}
            maxOptions={maxOptions}
            minOptions={minOptions}
            editOption={handleOnChangeOption}
            addOption={handleOnAddOption}
            showExplanation={showExplanation}
            contentMaxLength={150}
            deleteOption={() => {
              setOptions([...options] as GenericQuizOptionModel[]);
            }}
          />
        )}
      </>
    </CreateCardBaseForm>
  );
};

export default CreateGenericQuizCardForm;
