import React from "react";
import { InteractiveCard } from "../../../../core/models/user-progress/interactive-card.model";
import { StoreInterface } from "../../../../core/redux/stores/root.reducer";
import { ColumnsType } from "antd/es/table";
import { connect } from "react-redux";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { selectUsers } from "../../../../core/redux/selectors/user/user.selector";
import { Capitalize } from "../../../../core/utils/helper.utils";
import CardTag from "../../../components/card-answer/tags/card-tag";
import QuizCardModel from "../../../../core/models/content/card-types/quiz/quiz-card.model";
import UserModel from "../../../../core/models/user/user.model";
import Moment from "moment";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import GenericQuizCardModel from "../../../../core/models/content/card-types/generic-quiz/generic-quiz-card.model";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import GenericCardMeta from "../../../components/card/generic-card/generic-card-meta";

interface StateProps {
  users: UserModel[];
}

interface OwnProps {
  data: InteractiveCard[];
  card: QuizCardModel | GenericQuizCardModel;
}

type Props = OwnProps & StateProps;

const CardDetailOverviewQuizScreen = ({ data, card, users }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnsType<InteractiveCard> = [
    {
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "userUid",
      render: function renderText(_, data) {
        const user = users.find((u) => u.sub === data.userUid);
        return user ? user.name : "-";
      },
    },
    {
      title: Capitalize(t("common.time-completed")),
      dataIndex: "completedAt",
      sorter: (a, b) => {
        if (!a.completedAt || !b.completedAt) {
          return 0;
        }
        return +new Date(a.completedAt) - +new Date(b.completedAt);
      },
      render: function renderText(_, data) {
        return data.completedAt
          ? Moment(data.completedAt).format("DD/MM/YYYY (hh:mm:ss A)")
          : "-";
      },
    },
    {
      title: Capitalize(t("common.answer")),
      dataIndex: "answer",
      sorter: (a, b) => a.answer - b.answer,
      render: function renderText(_, data) {
        if (data.answer === undefined) {
          return null;
        }
        return TranslateDefaults(card.data.options[data.answer].content);
      },
    },
    {
      title: Capitalize(t("common.correct-or-incorrect-label")),
      dataIndex: "answer",
      sorter: (a, b) => a.answer - b.answer,
      render: function renderText(_, data) {
        if (data.answer === undefined) {
          return null;
        }
        return renderAnswerStatus(card.data.options[data.answer].isCorrect);
      },
    },
  ];

  return (
    <>
      {card.cardType === CardTypesEnum.GENERIC_QUIZ && (
        <GenericCardMeta card={card} />
      )}
      <Table<InteractiveCard>
        columns={columns}
        dataSource={data}
        rowKey={(data) => data.userUid}
        tableLayout={"fixed"}
        scroll={{ x: true }}
        size={"middle"}
      />
    </>
  );

  function renderAnswerStatus(isCorrect: boolean) {
    const type = isCorrect ? "correct" : "incorrect";
    return <CardTag type={type} label={Capitalize(t(`common.${type}`))} />;
  }
};

const mapStateToProps = (state: StoreInterface): StateProps => ({
  users: selectUsers(state),
});

export default connect(mapStateToProps)(CardDetailOverviewQuizScreen);
